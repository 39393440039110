<template>
  <div id="MinhaEmpresa" class="py-3 px-0">
    <!-- Cabeçalho - Titulo / Botão Editar-->
    <v-toolbar-title
      v-bind:style="{ 'color':COR_SUBTITULO }" 
      class="d-flex align-center justify-space-between body-1 font-primary ml-4"
    >
      <p
        class="font-primary font-weight-bold font-weight-medium body-1" 
        v-bind:style="{ 'color':COR_SUBTITULO }"
      >
        Minha Empresa
      </p>
      <!--  :disabled="!store_Configuracoes.priv_busca_privilegios_aplicacao_evento('Empresa.Editar')" -->
      <v-btn 
        v-if="store_Configuracoes.acao == 'C'"
        @click="store_Configuracoes.acao = 'E'"
        class="btn mr-4 pr-6"
        style="background-color: #dce1eb"
        elevation="0"
      >
        <v-icon class="btn-icon mr-1" color="light-blue accent-4"
          >mdi-pencil-outline</v-icon
          >
        <span 
        class="text-none font-weight-medium" 
        v-bind:style="{ 'color':COR_SUBTITULO }"
        >Editar</span
        >
      </v-btn>
    </v-toolbar-title> 

    <!-- ARQUIVO/WEBCAM DIALOG ------------------------------------------ -->
    <v-dialog
    v-model="store_Configuracoes.dialogDadosBasicos"
      transition="dialog-bottom-transition"
      max-width="400"
      class="pa-0"
    >
    </v-dialog>
    
    <!-- Formulario Consulta/Edição -->
    <v-form v-model="valid" id="form" ref="form" class="mx-0 mt-3" lazy-validation>
      <v-container class="mt-2 d-flex flex-column">
        <!-- 1ª Linha - Avatar / Nome / CNPJ -->
        <v-row no-gutter class="pt-0 mb-n14">
          <!-- Avatar da Empresa -->
          <v-col sm="4" class="pt-2 px-2 justify-center">
            <div class="d-none d-sm-flex justify-center">
              <v-avatar
                size="100px"
                v-ripple
                v-if="store_Configuracoes.acao == 'C'"
                class="grey lighten-2"
              >
                <span
                  v-if="!store_Configuracoes.empresa_selecionada.imagem"
                  color="#274ABB"
                  class="headline"
                >
                  <!-- {{ nomeIniciais(!store_Configuracoes.empresa_selecionada.nome_empresa) }} -->
                </span>
                <img
                  v-if="store_Configuracoes.empresa_selecionada.imagem"
                  :src="store_Configuracoes.empresa_selecionada.imagem"
                  alt="avatar"
                />
              </v-avatar>

              <v-avatar
                size="100px"
                v-ripple
                v-if="store_Configuracoes.acao != 'C'"
                class="grey lighten-2"
              >
                <span
                  v-if="!store_Configuracoes.empresa_selecionada.imagem"
                  v-on:click="dialog_crop = true"
                >
                  Clique p/ adicionar avatar
                </span>
                <img
                  v-if="store_Configuracoes.empresa_selecionada.imagem"
                  v-on:click="dialog_crop = true"
                  :src="store_Configuracoes.empresa_selecionada.imagem"
                  alt="avatar"
                />
              </v-avatar>
            </div>
          </v-col>

          <!-- Nome da Empresa -->
          <v-col md="8">
            <v-text-field
              v-model="store_Configuracoes.empresa_selecionada.nome"
              :readonly="store_Configuracoes.acao == 'C'"
              class="input-nome mb-0"
              loader-height="1"
              label="Nome da Empresa"
              placeholder="Nome da Empresa"
              background-color="#FFF"
              maxlength="60"
              light
              filled
              autofocus
              outlined
              required
              dense
              :rules="nomeEmpresaRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- 2ª Linha - CNPJ / Responsavel pela Empresa -->
        <v-row no-gutter class="pt-0 mb-n10">
          <v-col cols="4"></v-col>
           <!-- CNPJ-->
           <v-col md="3" class="pt-0"> 
            <v-text-field
              v-model="store_Configuracoes.empresa_selecionada.cnpj"
              :readonly="store_Configuracoes.acao == 'C'"
              class="mb-0"
              loader-height="1"
              label="CNPJ"
              placeholder="CNPJ"
              background-color="#FFF"
              maxlength="18"
              light
              filled
              outlined
              required
              dense
              v-mask="MASK_CPFCNPJ"
              :rules="cnpjRules"
            ></v-text-field>
          </v-col>
          <!-- Responsável pela Empresa-->
          <!-- Com a Requisicao colocar responsavel.pessoa_nome -->
          <v-col md="5" class="pt-0" >
            <v-text-field
              v-model="store_Configuracoes.empresa_selecionada.util_pessoa.pessoa_nome"  
              :readonly="true"
              label="Responsável pela Empresa"
              placeholder="Responsável pela Empresa"
              class="mb-0"
              filled  
              outlined
              dense
              background-color="#fff  "
              item-text="name"
            >
              <template v-slot:append-outer>
                  <v-icon
                    @click="store_Configuracoes.empresa_selecionada.util_pessoa = {}, store_Configuracoes.empresa_selecionada.cod_responsavel= null"
                    :disabled="store_Configuracoes.acao == 'C' "
                    class="input-conjuge mb-0"
                    color="primary"
                  >
                    mdi-delete
                  </v-icon>
                  <v-icon
                  @click="dialog_seleciona_responsavel = true"
                  :disabled="store_Configuracoes.acao == 'C'"
                  class="ml-2"
                  color="primary">
                  mdi-magnify-plus
                  </v-icon>
                </template>
            </v-text-field>
          </v-col>
        </v-row>
        <!-- 3ª Linha - Telefone / Celular / Email -->
        <v-row no-gutter class="pt-0 mb-n10">
          <!-- Telefone / Celular-->
          <v-col md="6"> 
             <v-text-field
              v-model="store_Configuracoes.empresa_selecionada.fones"
              :readonly="store_Configuracoes.acao == 'C'"
              class="mb-0"
              loader-height="1"
              label="Telefone / Celular"
              placeholder="Telefone / Celular"
              background-color="#FFF"
              light
              filled
              outlined
              dense
              v-mask="MASK_TELEFONECELULAR"     
            ></v-text-field>
          </v-col>
          <!-- EMAIL -->
          <v-col md="6"> 
              <v-text-field
              v-model="store_Configuracoes.empresa_selecionada.email"
              :readonly="store_Configuracoes.acao == 'C'"
              class="mx-0"
              loader-height="1"
              label="Email"
              placeholder="Digite o email"
              background-color="#FFF"
              maxlength="200"
              light
              filled
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- 3ª Linha - CEP / Endereço / Número -->
        <v-row no-gutter class="pt-0 mb-n10">
          <!-- CEP -->
          <v-col md="3" >
            <v-text-field
                  v-model="store_Configuracoes.empresa_selecionada.cep"
                  :readonly="store_Configuracoes.acao == 'C'"
                  class="mb-0"
                  light
                  background-color="#FFF"
                  label="Cep"
                  placeholder="xxxxx-xxx"
                  maxlength="10"
                  filled
                  outlined
                  required
                  dense
                  v-mask="MASK_CEP"
                 
                  >
         <!-- Botão Consulta CEP -->
         <template v-slot:append-outer>
            <v-icon
              class="btn-icon-plus input-conjuge mb-0"
              :readonly="store_Configuracoes.acao == 'C'"
              color="accent-4"
              @click="buscaEndereco(store_Configuracoes.empresa_selecionada.cep)"
            >
              mdi-magnify
            </v-icon>
          </template>
            </v-text-field>
          </v-col>
          <!-- Endereço -->
          <v-col md="6">
            <v-text-field
              v-model="store_Configuracoes.empresa_selecionada.endereco"
              :readonly="store_Configuracoes.acao == 'C'"
              class="mb-0"
              light
              background-color="#FFF"
              label="Endereço"
              placeholder="Endereço"
              maxlength="35"
              filled
              outlined
              required
              dense
              :rules="enderecoRules"
            >
            </v-text-field>
          </v-col>
          <!-- Número -->
          <v-col md="3">
            <v-text-field
              v-model="store_Configuracoes.empresa_selecionada.num_end"
              :readonly="store_Configuracoes.acao == 'C'"
              class="mb-0"
              light
              max-length="5"
              background-color="#FFF"
              label="Número"
              placeholder="Número"
              filled
              outlined
              dense
              maxlength="5"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- 4ª Linha - Bairro /  Complemento / Município / -->
        <v-row no-gutter class="pt-0 mb-n10">
          <!-- Bairro -->
          <v-col md="3">
                  <v-text-field
                  v-model="store_Configuracoes.empresa_selecionada.bairro"
                  :readonly="store_Configuracoes.acao == 'C'"
                  class="mb-0"
                  light
                  background-color="#FFF"
                  label="Bairro"
                  placeholder="Bairro"
                  filled
                  outlined
                  dense
                  maxlength="30"
                />
          </v-col>
          <!-- Complemento -->
          <v-col md="5">
              <v-text-field
                  v-model="store_Configuracoes.empresa_selecionada.complemento"
                  :readonly="store_Configuracoes.acao == 'C'"
                  class="mb-0"
                  light
                  background-color="#FFF"
                  label="Complemento"
                  placeholder="Complemento"
                  maxlength="20"
                  filled
                  outlined
                  dense
              />
          </v-col>
          <!-- Municipio -->
          <v-col md="4">
              <v-autocomplete
                  v-model="store_Configuracoes.empresa_selecionada.util_municipio"
                  :readonly="store_Configuracoes.acao == 'C'"
                  :loading="loading"
                  :search-input.sync="search"
                  :items="arrayMunicipios"
                  class="mb-0"
                  no-filter
                  hide-selected
                  auto-select-first
                  label="Município"
                  item-text="nome_exibicao"
                  item-value="codigo"
                  maxlength="35"
                  filled
                  outlined
                  dense
                  background-color="#FFF"
                  required
                  return-object
                  :rules="municipioRules"
                ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <!-- Rodapé - Botões Salvar / Cancelar -->
      <v-footer
        v-if="store_Configuracoes.acao != 'C'"
        color="transparent"
        elevation="0"
        class="footer justify-center mt-3 pt-3 pb-n3"
        fixed
        style="position: static"
      >
        <v-btn
          v-if="store_Configuracoes.acao == 'E'"
          @click="cancelar()"
          cer
          id="btn_cancelar"
          ref="btn_cancelar"
          class="mr-4 caption font-weight-medium"
          color="primary"
          text
        >
          Cancelar
        </v-btn>
        <v-btn
          :loading="loading"
          :disabled="!valid"
          @click="AtualizaMinhaEmpresa()"
          class="btn caption font-weight-medium white--text"
          color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-form>
    <!-- Arquivo/Webcam Dialog ------------------------------------------ -->
    <v-container v-if="dialog_crop">
      <v-dialog
        v-if="dialog_crop"
        :value="true"
        persistent
        style="width: 330"
        :max-width="330"
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>Foto</v-card-title>
          <v-card-text class="pt-7">
            <VueCropImage
              :img="store_Configuracoes.empresa_selecionada.imagem"
              :imgsync.sync="store_Configuracoes.empresa_selecionada.imagem"
              :dialog_crop.sync="dialog_crop"
              ref="VueCropImage"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Modal de seleção do Responsavel pela Empresa -->
    <v-container v-if="dialog_seleciona_responsavel">
      <v-dialog
        v-model="dialog_seleciona_responsavel"
        transition="dialog-bottom-transition"
        max-width="440"
      >
        <v-card>
          <ModalSelecaoPessoa
            :ls_Selecionar="true"
            @selecionaPessoa="selecionaResponsavel" />
        </v-card>
      </v-dialog>
    </v-container>
  </div>

</template>

<script>
import store_Configuracoes from "./store_Configuracoes";
import store_site from "../../store/store_site";
import ModalSelecaoPessoa from "../ModalNegociacao/ModalSelecaoPessoa.vue";
import store_ModalNegociacao from '../ModalNegociacao/store_ModalNegociacao'
import VueCropImage from "../ComponentesGlobais/CropImage/VueCropImage.vue";
import { baseURL, API } from "../../services/API";
import axios from "axios";
import { nomeIniciais } from "../../services/funcoes"
import { computed } from 'vue';




import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO,
  MASK_CPFCNPJ,
  MASK_TELEFONE,
  MASK_TELEFONECELULAR,
  MASK_CEP,
} from "../../services/constantes";

export default {
  name: "MinhaEmpresa",

  components: {
    //DatePicker,
    VueCropImage,
    ModalSelecaoPessoa
  },
 data() {
    return {
      store_Configuracoes: store_Configuracoes,
      store_site         : store_site,
      store_ModalNegociacao : store_ModalNegociacao,
      MASK_CPFCNPJ      : MASK_CPFCNPJ,
      MASK_CEP          : MASK_CEP,
      MASK_TELEFONE     : MASK_TELEFONE,
      MASK_TELEFONECELULAR: MASK_TELEFONECELULAR,
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO, 
      arrayMunicipios   : [],
      array_cep         : {},
      loading           : false,
      search            : null,
      enderecoEmpresaSelecionada: null,
      dialog_crop: false,
      cpfRules: [],
      // nomeIniciais      : nomeIniciais,

      valid: true,


      alert           : false,
      alert_msg       : "",

      dialogCancelar: false,
      dialog_seleciona_responsavel: false,

      rules: {
        min(min, v) { 
          return (v || '').length >= min || `Mínimo de ${min} caracteres`;
        },
        max(max, v) { 
          return (v || '').length <= max || `Máximo de ${max} caracteres`;
        },
      },

      nomeEmpresaRules: [
        (value) => !!value || "O nome da Empresa é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O nome deve ter menos do que 60 caracteres",

      ],
   

      cnpjRules: [
        (value) => !!value || "O CNPJ é obrigatório",
        (value) =>
          (value && value.length <= 18) ||
          "O CNPJ deve ter 18 caracteres",
      ],
     
    
      enderecoRules: [
        (value) => !!value || "O endereço é obrigatório",
        (value) =>
          (value && value.length <= 59) ||
          "O endereço deve ter no máximo 60 caracteres",
      ],
      municipioRules: [(value) => !!value || "O municipio é obrigatório"],
    };
  },
        
  valid: true,
  loading: false,
  alert: false,
  alert_msg: "",
  dialog_crop: false,

  /* Formulário Reprovação */
  snack: false,
  snackColor: "",
  snackText: "",
  form: false,

  dados: {
    foto: null,                   
    nome: "",                 
    cpf: "",                    
    email: "",                  
    telefone: "",             
    celular: "",                

    cep: "",                 
    logradouro: "",              
    numero: "",             
    complemento: "",            
    bairro: "",                 
    uf: "",                      
    cidade: "",                 

    observacoes: "",            
  },

  /* SELECT RESPONSAVEL */
  responsavel: [
    "Fernando Garcia", "Bruno Duarte", "João Feliciano",
  ],

  /* CIDADE */
  endereco_cidade: [
  'Franca',
  'Claraval',
  ],

  /* Menu Edição (Button Dots) */
  items: [
    {
      title: "Editar",
    },
    {
      title: "Excluir",
    },
  ],
   
   watch: {
    search(query) {
      if (query && (!this.select || this.select.text !== query)) {
        this.querySearch(query);
      }
    },
  },

  async created() {
    let resposta = await API.get("/configuracao/empresa");
    console.log("resp", resposta)
    if (resposta.status == 200) {
      this.store_Configuracoes.empresa_selecionada = resposta.data.result.result;
      this.arrayMunicipios = [{
        ...this.store_Configuracoes.empresa_selecionada.util_municipio,
        nome_exibicao: `${this.store_Configuracoes.empresa_selecionada.util_municipio.nome} - ${this.store_Configuracoes.empresa_selecionada.util_municipio.uf}`
      }]
      this.store_Configuracoes.empresa_edicao = this.store_Configuracoes.empresa_selecionada
    } else console.log("=====================");
    console.log("ewfwfew",this.store_Configuracoes.empresa_selecionada)
  },

  methods: {
    fecha_alert() {
      this.alert = false;
    },

    validate () {
      this.$refs.form.validate()
    },

     querySearch(query){
      let p_params = { params: { filtro: query } }
      this.loading = true
      this.busca_timeout(p_params);
      this.loading = false
    },

     busca_timeout(val) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(async () => {
      const ls_Rel_Municipios = await API.get(`municipio`, val);
      console.log(ls_Rel_Municipios)
      if (Array.isArray(ls_Rel_Municipios.data.result)) {
        ls_Rel_Municipios.data.result.map(el => el.nome_exibicao = `${el.nome} - ${el.uf}`)
        this.arrayMunicipios = ls_Rel_Municipios.data.result;
      }
      }, 600);
    },

    // Métodos da Minha Empresa
    async AtualizaMinhaEmpresa() {
      const lb_valido = this.$refs.form.validate();
      if(lb_valido) {
        let ls_Rel = null;
        this.store_Configuracoes.empresa_selecionada.cod_municipio = this.store_Configuracoes.empresa_selecionada.util_municipio.codigo
        var lo_params = {...JSON.parse(JSON.stringify(this.store_Configuracoes.empresa_selecionada))};
        if (this.store_Configuracoes.acao == "E") {
          ls_Rel = await this.store_Configuracoes.EmpresaPut(lo_params);
        }
        if (ls_Rel.status == 200 ) {
          this.store_Configuracoes.acao = "C";
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 1500;
          this.store_site.alert_msg = ls_Rel.data.result.result;
          this.store_site.alert = true;
          this.store_Configuracoes.empresa_edicao = this.store_Configuracoes.empresa_selecionada
        }
        else {
          this.store_site.alert_cor = "#FF0000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Rel.data.result;
          this.store_site.alert = true;
        }
      }
    },

    cancelar() {
      if (this.store_Configuracoes.acao == "E") {
        this.store_Configuracoes.empresa_selecionada = {
          ...this.store_Configuracoes.empresa_edicao
        };
      }
      this.store_Configuracoes.acao = "C";
    },

    // Seleciona o Responsavel pela empresa
    async selecionaResponsavel(val) {
        this.store_Configuracoes.empresa_selecionada.util_pessoa = {
          cod_pessoa: val.cod_pessoa,
          pessoa_nome: val.pessoa_nome,
        }
        this.store_Configuracoes.empresa_selecionada.cod_responsavel = val.cod_pessoa;
        this.dialog_seleciona_responsavel = false;
      },

      // Busca o endereço pelo CEP
      async buscaEndereco(val) {
        console.log('buscaEndereco chamado com valor:', val);
        if (val && val.length == 9) {
          const ls_Rel_Cep = await axios.get(
            `https://viacep.com.br/ws/${val}/json/`
          );
          console.log("Resp", ls_Rel_Cep)
          if (!ls_Rel_Cep.data.erro) {
            this.array_cep = ls_Rel_Cep.data;
            this.store_Configuracoes.empresa_selecionada.logradouro = this.array_cep.logradouro;
            this.store_Configuracoes.empresa_selecionada.bairro = this.array_cep.bairro;

            //const ls_Rel_Municipios = await API.get(`municipio`, {params: { cod_ibge: this.array_cep.ibge }});

            //if (ls_Rel_Municipios.data.result.length > 0) {
              this.arrayMunicipios = [ { codigo         : this.array_cep.ibge,
                                         nome          : this.array_cep.localidade,
                                         uf            : this.array_cep.uf, 
                                         nome_exibicao : this.array_cep.localidade + ' - ' + this.array_cep.uf
                                        }]
              this.store_Configuracoes.empresa_selecionada.util_municipio.codigo = this.array_cep.ibge
            //}
        }
      }
   },
  },
};
</script>

<style scoped>
#MinhaEmpresa {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #MinhaEmpresa {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#MinhaEmpresa::-webkit-scrollbar {
  width: 5px;
}

#MinhaEmpresa::-webkit-scrollbar-button {
  padding: 1px;
}

#MinhaEmpresa::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#MinhaEmpresa::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #MinhaEmpresa::-webkit-scrollbar {
    width: initial;
  }

  #MinhaEmpresa::-webkit-scrollbar-button {
    padding: initial;
  }

  #MinhaEmpresa::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #MinhaEmpresa::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

@media(max-width: 599px) {
  .col-avatar {
    margin-bottom: 56px;
  }

  .segunda-row div:nth-child(2) {
    margin-top: -12px;
  }

  .segunda-row div:nth-child(3) {
    margin-top: -12px;
  }

  .terceira-row {
    margin-top: 12px!important;
  }
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA)!important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px!important;
}

.theme--light.v-list-item--active:before, .theme--light.v-list-item--active:hover:before, .theme--light.v-list-item:focus:before {
  color: #FF0000;
}
</style>
