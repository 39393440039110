import Vue from "vue";
import { API } from "../../services/API"

function initialState() {
  return {
    dados: [],
    acao: "C", // E=Edicao, I=Inclusão, C=Consulta
    empresa_selecionada: {},  // Empresa Selecionada para Edição
    empresa_edicao: {}, // 
    

    currentPage: 1,
    count_dados: null,

  
    // Abas do container 
    abas: [
      ['mdi-text-box-outline', 'Dados', '0'],
      ['mdi-cash-multiple', 'Orçamento', '0'],
      ['mdi-timer-sand-complete', 'Cronograma', '0'],
      ['mdi-tooltip-text-outline', 'Proposta', '0'],
      ['mdi-folder-multiple-plus-outline', 'Documentos', '0'],
      ['mdi-text-box-multiple-outline', 'Relatorios', '0'],
    ],
  }
}

var vm_store_Configuracoes = new Vue({

  data() {
    return initialState();
  },

  methods: {
    resetData() {
      Object.assign(this.$data, initialState());
    },

    // Métodos da Empresa - Gets / Post / Put / Delete
    // Método - Get All
    async EmpresasGet(p_params) {
      const resposta = await API.get("/empresas", { params: { ...p_params } });
      if (resposta) {
        this.dados = [];
        if (resposta.data.result != 'Não possui dados') {
          this.dados = resposta.data.result
          this.count_dados = resposta.data.result.length;
          return resposta.data.result
        }
      }
    },
    // Método - Get Individual
    async EmpresaGet(p_params) {
      const resposta = await API.get(`/empresas/${p_params.cod_empresa}`);
      return resposta.data.result;
    },
    // Método - Put
    async EmpresaPut(p_params) {
      const resp = await API.put(`/configuracao/empresa`, p_params);
      return resp;
    },


    // Métodos de Endereços - Delete
    // Método - Delete
    async EnderecoDelete(p_params) {
      const resp = await API.delete(`/empresas/enderecos/${p_params.cod_endereco}`);
      return resp;
    },

    // Métodos de Empresas- Gets / Post / Put / Delete
    // Método - Get All 
    async InsumoPost(body) {
      const resp = await API.post(`/empresas`, JSON.stringify(body));
      return resp;
    },

    // Métodos de Consulta de Documentos GED
    // Tela de Documentos ==================================================================
    async EmpresaGedGet(p_params) {
      const resposta = await API.get("empresa_ged", {
        params: {
          ...p_params
        },
      }
      );
      // console.log(p_params, resposta)

      if (resposta) {
        if (resposta.data.result != 'Não possui dados') {
          return resposta.data.result
        }
      }
    },

    async EmpresaGedDelete(body) {
      // console.log(body);
      // return
      const resposta = await API.delete(`ged_tipo_documento/${body.cod_ged}`)

      const { data } = resposta;
      if (resposta.status == 200)
        return data.result;
      else
        return data;

    },

  },
  
  watch: {
    // docDocumentos()           { this.atualiza_abas() },
    // docContrato()             { this.atualiza_abas() },
    // docTransferencia()        { this.atualiza_abas() },
    // docRenogociacao()         { this.atualiza_abas() },
    // docRescisao()             { this.atualiza_abas() },
    // docAutorizacaoEscritura() { this.atualiza_abas() },
    // docEscritura()            { this.atualiza_abas() },
    // docMensagens()            { this.atualiza_abas() }
  },
})

export default vm_store_Configuracoes
